import { useState } from "react";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
  Stack,
  Typography,
  Paper,
  Modal,
  IconButton,
} from "@mui/material";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  MemoryRouter,
} from "react-router-dom";
import { SimpleXLogo } from "./CustomIcons";
import { ExpandMore } from "@mui/icons-material/";
import { TimeDisplay } from "./Time";

function LogoBox(props: { text: string; href: string; img: string }) {
  return (
    <Paper
      elevation={6}
      sx={{
        p: 2,
        ":hover": { boxShadow: 12 },
      }}
    >
      <Link href={props.href} underline="none" color="inherit">
        <Stack spacing={1} alignItems="center">
          <img src={props.img} alt={props.text} height="160" />
          <br />
          <Typography align="center" variant="h4">
            {props.text}
          </Typography>
        </Stack>
      </Link>
    </Paper>
  );
}

function ExtRouterLogoBox(props: { text: string; href: string }) {
  return (
    <Paper
      elevation={6}
      sx={{
        p: 2,
        ":hover": { boxShadow: 12 },
      }}
    >
      <Link
        component={RouterLink}
        to={props.href}
        underline="none"
        color="inherit"
      >
        <Stack spacing={0} alignItems="center">
          {TimeDisplay(false, true, 4)}
          <br />
          <Typography align="center" variant="h4">
            {props.text}
          </Typography>
        </Stack>
      </Link>
    </Paper>
  );
}

function RouterLogoBox(props: { text: string; href: string; img: string }) {
  return (
    <Paper
      elevation={6}
      sx={{
        p: 2,
        ":hover": { boxShadow: 12 },
      }}
    >
      <Link
        component={RouterLink}
        to={props.href}
        underline="none"
        color="inherit"
      >
        <Stack spacing={1} alignItems="center">
          <img src={props.img} alt={props.text} height="160" />
          <br />
          <Typography align="center" variant="h4">
            {props.text}
          </Typography>
        </Stack>
      </Link>
    </Paper>
  );
}

function CalendarBox() {
  return (
    <Paper
      elevation={6}
      sx={{
        p: 2,
        ":hover": { boxShadow: 12 },
      }}
    >
      <Link
        href="https://shkspr.mobi/blog/2018/02/this-svg-always-shows-todays-date/"
        underline="none"
        color="inherit"
      >
        <Stack spacing={1} alignItems="center">
          <object data="calendar.svg" type="image/svg+xml" />
          <br />
          <Typography align="center" variant="h4">
            aujourd'hui
          </Typography>
        </Stack>
      </Link>
    </Paper>
  );
}

function FrenchBook() {
  const design = localStorage.getItem("design");
  return (
    <Box sx={{ display: "inline-block" }}>
      <Paper
        elevation={6}
        sx={{
          p: 2,
          ":hover": { boxShadow: 12 },
        }}
      >
        <Link href="/le_français/" underline="none" color="inherit">
          <Stack spacing={1} alignItems="center">
            <img src="flag_of_france.svg" alt="flag of France" height="100" />
            <Box
              component="img"
              src="le_français.svg"
              alt="Le Français par la Methode Nature"
              sx={{
                height: "100px",
                filter: design === "dark" ? "invert(1)" : "invert(0)",
              }}
            />
          </Stack>
        </Link>
      </Paper>
    </Box>
  );
}

function SimpleXChatInfo() {
  return (
    <Box alignSelf="center">
      <Accordion disableGutters>
        <AccordionSummary expandIcon={<ExpandMore />} id="panel1a-header">
          <Typography variant="h4">SMP server</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Link
            href="smp://_iLMA0dyoAW8It6TelP0N0b9oTr1TUB5vRqtIhZWSdw=@0124816.xyz"
            underline="hover"
            color="text.primary"
          >
            <Box maxWidth="300px" sx={{ width: "96vw" }}>
              <img
                src="simplex.chat.smp.svg"
                alt="simpleX chat SMP server address"
                width="300px"
              />
            </Box>
          </Link>
        </AccordionDetails>
      </Accordion>

      <Accordion disableGutters>
        <AccordionSummary expandIcon={<ExpandMore />} id="panel1a-header">
          <Typography variant="h4">XFTP server</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Link
            href="xftp://WXKWpo0TDuJNuRx1li7xBYrMvvzxFfEmCDkgZXFuVxM=@0124816.xyz:8097"
            underline="hover"
            color="text.primary"
          >
            <Box maxWidth="300px" sx={{ width: "96vw" }}>
              <img
                src="simplex.chat.xftp.svg"
                alt="simpleX chat XFTP server address"
                width="300px"
              />
            </Box>
          </Link>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

function SimpleXChat(props: { handleOpen: () => void }) {
  return (
    <Paper
      elevation={6}
      sx={{
        p: 2,
        ":hover": { boxShadow: 12 },
      }}
    >
      <IconButton
        onClick={props.handleOpen}
        color="inherit"
        sx={{ "&:hover": { backgroundColor: "transparent" } }}
      >
        <Stack spacing={1} alignItems="center">
          <SimpleXLogo /> 
          <Typography align="center" variant="h4">
            SimpleX chat
          </Typography>
        </Stack>
      </IconButton>
    </Paper>
  );
}

export default function LinkDisplay() {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Box
      alignItems="center"
      display="flex"
      justifyContent="center"
      sx={{
        borderRadius: 1,
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        gap: 6,
        m: 8,
        p: 4,
      }}
    >
      <FrenchBook />
      <CalendarBox />
      {routerItemData.map((item) => (
        <RouterLogoBox
          key={item.text}
          text={item.text}
          href={item.href}
          img={item.img}
        />
      ))}
      {itemData.map((item) => (
        <LogoBox
          key={item.text}
          text={item.text}
          href={item.href}
          img={item.img}
        />
      ))}
      <ExtRouterLogoBox text="time" href="time" />

      <SimpleXChat handleOpen={handleOpen} />

      <Modal
        open={open}
        onClose={handleClose}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <SimpleXChatInfo />
      </Modal>
    </Box>
  );
}

const routerItemData = [
  { text: "phrase generator", href: "phrases", img: "password_strength.png" },
  { text: "speckle generator", href: "speckles", img: "speckles_logo.svg" },
];

const itemData = [
  // {
  //   text: "LibreTranslate",
  //   href: "https://translate.0124816.xyz",
  //   img: "libretranslate.svg",
  // },
  {
    text: "searXNG",
    href: "https://search.0124816.xyz",
    img: "searxng.svg",
  },
  {
    text: "gitea",
    href: "https://git.0124816.xyz",
    img: "gitea.svg",
  },
  {
    text: "CryptPad",
    href: "https://cryptpad.0124816.xyz",
    img: "cryptpad.svg",
  },
  {
    text: "Stirling-PDF",
    href: "https://pdf.0124816.xyz",
    img: "Stirling-PDF.svg",
  },
  {
    text: "CyberChef",
    href: "https://0124816.xyz/CyberChef",
    img: "cyberchef_robot.svg",
  },
  {
    text: "biblioreads",
    href: "https://biblio.0124816.xyz/",
    img: "biblioreads.svg",
  },
  // {
  //   text: "nitter",
  //   href: "https://nitter.0124816.xyz/",
  //   img: "nitter.svg",
  // },
  // {
  //   text: "photoprism",
  //   href: "https://photo.0124816.xyz",
  //   img: "photoprism.svg",
  // },
  // {
  //   text: "jellyfin",
  //   href: "https://jellyfin.0124816.xyz",
  //   img: "jellyfin.png",
  // },
  // {
  //   text: "audiobooks",
  //   href: "https://audiobooks.0124816.xyz",
  //   img: "audiobookshelf.svg",
  // },
  // { text: "calibre", href: "https://books.0124816.xyz", img: "library.png" },
];
